<template>
  <div class="admin">

    <div class="admin-area">

      <div class="row row-gutter-20">
        <div class="col-6">
          <admin-nav></admin-nav>
        </div>
        <div class="col-18">

          <header class="admin-header cf">
            <ul class="nav nav-header cf">
              <li>Fahrzeuge</li>
            </ul>
            <div class="header-actions">
              <p><router-link to="/admin/vehicles/new" class="button button-orange button-small">Fahrzeug hinzufügen ›</router-link></p>
            </div>
          </header>

          <div class="admin-body">

            <div class="search-wrap">
              <input v-model="search_phrase" v-on:input="search_handler" type="text" class="search-field" placeholder="Suchbegriff eingeben...">
            </div>

            <div v-if="loading">
              <div class="loading-wrap">
                <div class="loading-spinner"></div>
              </div>
            </div>
            <div v-else>

              <div v-if="search_loading">
                <div class="loading-wrap">
                  <div class="loading-spinner"></div>
                </div>
              </div>
              <div v-else>

                <div v-if="vehicles.length > 0">

                  <table class="table">
                    <thead>
                      <tr>
                        <th width="5%"></th>
                        <th width="20%">Kennzeichen</th>
                        <th width="20%">Marke</th>
                        <th width="25%">Kategorie</th>
                        <th width="20%">Standort</th>
                        <th width="10%"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="vehicle in vehicles" v-bind:key="vehicle.id">
                        <td>
                          <span v-if="vehicle.status == 'enabled'" class="status status-green"></span>
                          <span v-if="vehicle.status == 'disabled'" class="status status-red"></span>
                        </td>
                        <td>
                          <router-link :to="'/admin/vehicles/'+vehicle.id">
                            {{ vehicle.license_plate }}
                          </router-link>
                        </td>
                        <td>{{ vehicle.make.name }}</td>
                        <td>{{ vehicle.category.name }}</td>
                        <td>{{ vehicle.location.name }}</td>
                        <td>
                          <router-link :to="'/admin/vehicles/'+vehicle.id">
                            <span class="material-icons">create</span>
                          </router-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>


                </div>
                <div v-else>
                  <p>Noch keine Fahrzeuge verfügbar.</p>
                </div>

              </div>

            </div>

          </div>

        </div>
      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import AdminNav from '../../../components/admin_nav'

export default {
  name: 'admin_reservations',
  components: {
    AdminNav
  },
  data () {
    return {
      table: {
        columns: [
          {
            label: 'Kennzeichen',
            field: 'license_plate',
          },
          {
            label: 'Kilometerstand',
            field: 'mileage'
          }
        ],
      },
      vehicles: [],
      search_phrase: "",
      search_loading: false,
      loading: true
    }
  },
  methods: {
    get_vehicles() {

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/vehicles', { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.vehicles = response.data.vehicles;
        this.loading = false;
      })
      .catch(error => {
        console.log(error);
      })

    },
    search_handler() {
      if (this.search_loading == false) {
        this.search_loading = true;
        axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/vehicles?search_phrase='+this.search_phrase, { headers: { Authorization: this.$store.getters.getToken } })
        .then(response => {
          this.vehicles = response.data.vehicles;
          this.search_loading = false;
        })
        .catch(error => {
          console.log(error);
        })
      }
    }
  },
  mounted () {
    this.get_vehicles();
  }
}
</script>

<style lang="scss">

</style>
